var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('h1',[_vm._v("VAT Number Lookup")]),_vm._m(0),_c('br'),_c('h5',[_vm._v("Sample API request")]),_c('p',[_vm._v(" Below is a request for a United Kingdom VAT number. Review our "),_c('router-link',{attrs:{"to":{ name: 'Authorization' }}},[_vm._v("authorization guide")]),_vm._v(" to authorize your request. ")],1),_vm._m(1),_c('br'),_c('h5',[_vm._v("Sample API responses")]),_c('p',[_vm._v(" In this response, the number submitted had an invalid format. The message adapts to provide specific guidance where available. Name and address properties will be empty strings when the result is not valid. ")]),_vm._m(2),_c('br'),_c('p',[_vm._v(" Here the number submitted passed the formatting validation step, but no matching entity was found, so ultimately it is also labelled as invalid. ")]),_vm._m(3),_c('br'),_vm._m(4),_vm._m(5),_c('br'),_c('p',[_vm._v(" When the result is valid, the message property is an empty string, and the name and address of the entity are returned. ")]),_vm._m(6),_c('br'),_c('h5',[_vm._v("Query parameters")]),_vm._m(7),_c('hr'),_vm._m(8),_c('hr'),_vm._m(9),_c('hr'),_c('p',[_vm._m(10),_vm._v(" Your API key. Learn how to "),_c('router-link',{attrs:{"to":{ name: 'Authorization' }}},[_vm._v("authorize")]),_vm._v(" your API requests, or "),_c('router-link',{attrs:{"to":{ name: 'Signup' }}},[_vm._v("get your API key here")]),_vm._v(". ")],1),_c('hr'),_c('h3',{attrs:{"id":"countries"}},[_vm._v("Lookup Availability by Country")]),_c('p',[_vm._v(" The following is a list of countries currently supported by our VAT lookup API. If you would like to request support for additional countries, please "),_c('router-link',{attrs:{"to":{ name: 'Contact' }}},[_vm._v("contact us")]),_vm._v("! ")],1),_vm._m(11),_c('br')])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" The "),_c('b',[_vm._v("Tax ID Pro")]),_vm._v(" API makes it easy to look up VAT numbers for EU countries plus the United Kingdom and Australia. The API returns JSON-encoded responses, and uses standard HTTP response codes. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box has-background-dark has-text-light",staticStyle:{"font-family":"monospace"}},[_c('span',{staticClass:"has-text-success"},[_vm._v("GET")]),_vm._v(" https://api.taxid.pro/lookup"),_c('br'),_c('div',{staticStyle:{"padding-left":"18px"}},[_vm._v(" ? country = "),_c('span',{staticClass:"has-text-warning"},[_vm._v("gb")]),_c('br'),_vm._v(" & tin = "),_c('span',{staticClass:"has-text-warning"},[_vm._v("553557881")]),_c('br')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box has-background-dark has-text-light",staticStyle:{"font-family":"monospace"}},[_vm._v(" {"),_c('br'),_c('div',{staticStyle:{"padding-left":"18px"}},[_vm._v(" \"valid\": "),_c('span',{staticClass:"has-text-danger"},[_vm._v("false")]),_vm._v(","),_c('br'),_vm._v(" \"message\": \"Invalid VAT Registration Number.\","),_c('br'),_vm._v(" \"name\": \"\","),_c('br'),_vm._v(" \"address\": \"\" ")]),_vm._v(" } ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box has-background-dark has-text-light",staticStyle:{"font-family":"monospace"}},[_vm._v(" {"),_c('br'),_c('div',{staticStyle:{"padding-left":"18px"}},[_vm._v(" \"valid\": "),_c('span',{staticClass:"has-text-danger"},[_vm._v("false")]),_vm._v(","),_c('br'),_vm._v(" \"message\": \"No entity found.\","),_c('br'),_vm._v(" \"name\": \"\","),_c('br'),_vm._v(" \"address\": \"\" ")]),_vm._v(" } ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" Tax ID Pro obtains VAT registration data from government sources. From time to time, those sources may become unavailable. In cases where the source is unavailable, the valid property will be "),_c('span',{staticClass:"value"},[_vm._v("null")]),_vm._v(". ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box has-background-dark has-text-light",staticStyle:{"font-family":"monospace"}},[_vm._v(" {"),_c('br'),_c('div',{staticStyle:{"padding-left":"18px"}},[_vm._v(" \"valid\": "),_c('span',{staticClass:"has-text-warning"},[_vm._v("null")]),_vm._v(","),_c('br'),_vm._v(" \"message\": \"GB service is currently unavailable.\","),_c('br'),_vm._v(" \"name\": \"\","),_c('br'),_vm._v(" \"address\": \"\" ")]),_vm._v(" } ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box has-background-dark has-text-light",staticStyle:{"font-family":"monospace"}},[_vm._v(" {"),_c('br'),_c('div',{staticStyle:{"padding-left":"18px"}},[_vm._v(" \"valid\": "),_c('span',{staticClass:"has-text-success"},[_vm._v("true")]),_vm._v(","),_c('br'),_vm._v(" \"message\": \"\","),_c('br'),_vm._v(" \"name\": \"Credite Sberger Donal Inc.\","),_c('br'),_vm._v(" \"address\": \"131B Barton Hamlet SW97 5CK\" ")]),_vm._v(" } ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" At a minimum, the query should include a "),_c('span',{staticClass:"value"},[_vm._v("country")]),_vm._v(" and a "),_c('span',{staticClass:"value"},[_vm._v("tin")]),_vm._v(" (short for tax ID number). You may also include your API key as a "),_c('span',{staticClass:"value"},[_vm._v("key")]),_vm._v(" parameter if you choose to authorize your request that way. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticClass:"param"},[_vm._v("country "),_c('span',{staticClass:"param-note"},[_vm._v("required")])]),_vm._v(" A two-letter country code, as defined in the "),_c('a',{attrs:{"href":"https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes"}},[_vm._v(" ISO standard")]),_vm._v(". ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticClass:"param"},[_vm._v("tin "),_c('span',{staticClass:"param-note"},[_vm._v("required")])]),_vm._v(" The VAT number (also known as a tax ID number). It may contain numbers, letters (uppercase or lowercase) dashes, or underscores. You may include a two-letter country code prefix as long as that prefix matches the country provided in the "),_c('span',{staticClass:"value"},[_vm._v("country")]),_vm._v(" parameter. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"param"},[_vm._v("key "),_c('span',{staticClass:"param-note"},[_vm._v("optional")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('ul',[_c('li',[_vm._v("Australia")]),_c('li',[_vm._v("Austria")]),_c('li',[_vm._v("Belgium")]),_c('li',[_vm._v("Bulgaria")]),_c('li',[_vm._v("Croatia")]),_c('li',[_vm._v("Cyprus")]),_c('li',[_vm._v("Czechia")]),_c('li',[_vm._v("Denmark")])])]),_c('div',{staticClass:"column"},[_c('ul',[_c('li',[_vm._v("Estonia")]),_c('li',[_vm._v("Finland")]),_c('li',[_vm._v("France")]),_c('li',[_vm._v("Germany")]),_c('li',[_vm._v("Greece")]),_c('li',[_vm._v("Hungary")]),_c('li',[_vm._v("Ireland")]),_c('li',[_vm._v("Italy")])])]),_c('div',{staticClass:"column"},[_c('ul',[_c('li',[_vm._v("Latvia")]),_c('li',[_vm._v("Lithuania")]),_c('li',[_vm._v("Luxembourg")]),_c('li',[_vm._v("Malta")]),_c('li',[_vm._v("Netherlands")]),_c('li',[_vm._v("Poland")]),_c('li',[_vm._v("Portugal")]),_c('li',[_vm._v("Romania")])])]),_c('div',{staticClass:"column"},[_c('ul',[_c('li',[_vm._v("Slovakia")]),_c('li',[_vm._v("Slovenia")]),_c('li',[_vm._v("Spain")]),_c('li',[_vm._v("Sweden")]),_c('li',[_vm._v("United Kingdom")])])])])])
}]

export { render, staticRenderFns }