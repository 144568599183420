<template>
  <div class="content">
    <h1>VAT Number Lookup</h1>

    <p>
      The <b>Tax ID Pro</b> API makes it easy to look up VAT numbers for EU countries plus
      the United Kingdom and Australia. The API returns JSON-encoded responses, and uses
      standard HTTP response codes.
    </p>
    <br />

    <h5>Sample API request</h5>
    <p>
      Below is a request for a United Kingdom VAT number. Review our
      <router-link :to="{ name: 'Authorization' }">authorization guide</router-link> to
      authorize your request.
    </p>

    <div class="box has-background-dark has-text-light" style="font-family: monospace">
      <span class="has-text-success">GET</span>
      https://api.taxid.pro/lookup<br />
      <div style="padding-left: 18px">
        ? country = <span class="has-text-warning">gb</span><br />
        &amp; tin = <span class="has-text-warning">553557881</span><br />
      </div>
    </div>

    <br />

    <h5>Sample API responses</h5>
    <p>
      In this response, the number submitted had an invalid format. The message adapts to
      provide specific guidance where available. Name and address properties will be empty
      strings when the result is not valid.
    </p>

    <div class="box has-background-dark has-text-light" style="font-family: monospace">
      {<br />
      <div style="padding-left: 18px">
        "valid": <span class="has-text-danger">false</span>,<br />
        "message": "Invalid VAT Registration Number.",<br />
        "name": "",<br />
        "address": ""
      </div>
      }
    </div>
    <br />
    <p>
      Here the number submitted passed the formatting validation step, but no matching
      entity was found, so ultimately it is also labelled as invalid.
    </p>

    <div class="box has-background-dark has-text-light" style="font-family: monospace">
      {<br />
      <div style="padding-left: 18px">
        "valid": <span class="has-text-danger">false</span>,<br />
        "message": "No entity found.",<br />
        "name": "",<br />
        "address": ""
      </div>
      }
    </div>
    <br />
    <p>
      Tax ID Pro obtains VAT registration data from government sources. From time to time,
      those sources may become unavailable. In cases where the source is unavailable, the
      valid property will be <span class="value">null</span>.
    </p>

    <div class="box has-background-dark has-text-light" style="font-family: monospace">
      {<br />
      <div style="padding-left: 18px">
        "valid": <span class="has-text-warning">null</span>,<br />
        "message": "GB service is currently unavailable.",<br />
        "name": "",<br />
        "address": ""
      </div>
      }
    </div>
    <br />
    <p>
      When the result is valid, the message property is an empty string, and the name and
      address of the entity are returned.
    </p>

    <div class="box has-background-dark has-text-light" style="font-family: monospace">
      {<br />
      <div style="padding-left: 18px">
        "valid": <span class="has-text-success">true</span>,<br />
        "message": "",<br />
        "name": "Credite Sberger Donal Inc.",<br />
        "address": "131B Barton Hamlet SW97 5CK"
      </div>
      }
    </div>

    <br />

    <h5>Query parameters</h5>

    <p>
      At a minimum, the query should include a
      <span class="value">country</span> and a <span class="value">tin</span> (short for
      tax ID number). You may also include your API key as a
      <span class="value">key</span> parameter if you choose to authorize your request
      that way.
    </p>

    <hr />

    <p>
      <span class="param">country <span class="param-note">required</span></span>
      A two-letter country code, as defined in the
      <a href="https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes">
        ISO standard</a
      >.
    </p>

    <hr />

    <p>
      <span class="param">tin <span class="param-note">required</span></span>
      The VAT number (also known as a tax ID number). It may contain numbers, letters
      (uppercase or lowercase) dashes, or underscores. You may include a two-letter
      country code prefix as long as that prefix matches the country provided in the
      <span class="value">country</span> parameter.
    </p>

    <hr />

    <p>
      <span class="param">key <span class="param-note">optional</span></span>
      Your API key. Learn how to
      <router-link :to="{ name: 'Authorization' }">authorize</router-link> your API
      requests, or
      <router-link :to="{ name: 'Signup' }">get your API key here</router-link>.
    </p>

    <hr />

    <h3 id="countries">Lookup Availability by Country</h3>

    <p>
      The following is a list of countries currently supported by our VAT lookup API. If
      you would like to request support for additional countries, please
      <router-link :to="{ name: 'Contact' }">contact us</router-link>!
    </p>

    <div class="box">
      <div class="columns">
        <div class="column">
          <ul>
            <li>Australia</li>
            <li>Austria</li>
            <li>Belgium</li>
            <li>Bulgaria</li>
            <li>Croatia</li>
            <li>Cyprus</li>
            <li>Czechia</li>
            <li>Denmark</li>
          </ul>
        </div>
        <div class="column">
          <ul>
            <li>Estonia</li>
            <li>Finland</li>
            <li>France</li>
            <li>Germany</li>
            <li>Greece</li>
            <li>Hungary</li>
            <li>Ireland</li>
            <li>Italy</li>
          </ul>
        </div>
        <div class="column">
          <ul>
            <li>Latvia</li>
            <li>Lithuania</li>
            <li>Luxembourg</li>
            <li>Malta</li>
            <li>Netherlands</li>
            <li>Poland</li>
            <li>Portugal</li>
            <li>Romania</li>
          </ul>
        </div>
        <div class="column">
          <ul>
            <li>Slovakia</li>
            <li>Slovenia</li>
            <li>Spain</li>
            <li>Sweden</li>
            <li>United Kingdom</li>
          </ul>
        </div>
      </div>
    </div>

    <br />
  </div>
</template>

<style scoped>
.column ul {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
.param {
  font-family: monospace;
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
}
.param-note {
  font-family: open-sans, sans-serif;
  font-weight: bold;
  color: #aaa;
  font-size: 0.75rem;
}
.value {
  display: inline-block;
  border: 1px solid #ddd;
  border-radius: 2px;
  background-color: #f5f5f5;
  font-size: 0.75rem;
  padding: 2px 4px 1px;
  font-family: monospace;
}
</style>

<script>
export default {
  name: 'Lookup',
  mounted() {
    if (this.$route.hash) location.hash = this.$route.hash
    else window.scrollTo(0, 0)
  },
}
</script>